<template>
  <div class="visit">
    <div class="visit_header">未来战场：意见反馈</div>
    <div class="visit_form">
      <div class="form_title">反馈类型<label>( 必选 )</label></div>
      <div class="form_type">
        <div
          :class="activeType === item.value ? 'active_item' : ''"
          class="item"
          v-for="(item, index) in typeOptions"
          :key="index"
          @click="chooseType(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="form_title">反馈内容<label>( 必选 )</label></div>
      <div class="form_input">
        <textarea
          placeholder="请输入您要反馈的问题（5-500字以内）"
          v-model="content"
        />
      </div>
      <div class="form_title">
        相关附件<span>问题出现的相关视频或者照片</span>
      </div>
      <div class="uploadBtn">
        <van-uploader
          v-model="fileList"
          multiple
          :max-count="3"
          :after-read="uploadImg"
        ></van-uploader>
        <!-- <div class="uploadBtn_solt">
            <img src="../assets/file.png" alt="" />
            <span>选择文件</span>
          </div> -->
      </div>
      <van-button
        type="primary"
        color="#6643FF"
        block
        @click="submitFrom"
        class="submitBtn"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
// import { reactive, onMounted } from "vue";
import { uploadFeedback } from "../utils/api";
import { uploadImgApi } from "../utils/api";
import { useRouter } from "vue-router";
export default {
  name: "Index",
  setup() {
    const router = useRouter();
    const typeOptions = [
      {
        value: "m",
        label: "管理端",
      },
      {
        value: "c",
        label: "背包",
      },
      {
        value: "g",
        label: "游戏",
      },
      {
        value: "d",
        label: "硬件",
      },
      {
        value: "a",
        label: "优化建议",
      },
      {
        value: "o",
        label: "其他",
      },
    ];
    const state = reactive({
      activeType: "m",
      fileList: [],
      content: "",
      files: [],
    });
    const chooseType = (e) => {
      state.activeType = e;
    };
    const submitFrom = () => {
      const obj = {
        created_by: "555555555",
        platform: "p",
        files: state.files,
        content: state.content,
        type: state.activeType,
        org_id: "2nXHXwa73x9dQqfhbMNM8Q",
      };
      const code = sessionStorage.getItem("code");
      uploadFeedback(code, obj).then((res) => {
        console.log("rrrr", res);
        if (res.status === 200) {
          router.push({ path: "resultPage" });
        }
      });
    };
    const uploadImg = (file) => {
      console.log("file", file.file);
      uploadImgApi(file).then((res) => {
        let newFile = [];
        newFile.push(res.data.url);
        state.files = [...state.files, ...newFile];
      });
    };
    return {
      typeOptions,
      ...toRefs(state),
      chooseType,
      submitFrom,
      uploadImg,
    };
  },
};
</script>

<style lang="scss" >
.visit {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  background: #fff;
  .visit_header {
    width: 100%;
    height: auto;
    overflow: hidden;
    box-sizing: border-box;
    padding: 64px 29px 29px 29px;
    color: #000;
    font-size: 29px;
    font-weight: bold;
    text-align: left;
    position: relative;
    z-index: 2;
  }
  .visit_header::after {
    position: absolute;
    right: 27px;
    top: 50px;
    width: 168px;
    height: 92px;
    background: url("../assets/logo.png") no-repeat;
    background-size: 100% 94%;
    content: "";
    z-index: -1;
    opacity: 0.4;
  }
  .visit_form {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 29px;
    box-sizing: border-box;
    .form_title {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
      label {
        font-size: 11px;
        color: #ff0000;
        margin-left: 10px;
      }
      span {
        color: #999999;
        font-size: 11px;
        margin-left: 10px;
      }
    }
    .form_type {
      width: 100%;
      height: auto;
      overflow: hidden;
      .item {
        width: 30%;
        height: 43px;
        background: #dbdbdb;
        color: #666666;
        border-radius: 7px;
        font-size: 13px;
        line-height: 43px;
        text-align: center;
        float: left;
        margin-right: 5%;
        margin-bottom: 10px;
      }
      .item:nth-child(3n) {
        margin-right: 0;
      }
      .active_item {
        background: #6643ff !important;
        font-weight: bold;
        color: #fff;
      }
    }
    .form_input {
      width: 100%;
      height: 178px;
      overflow: hidden;
      box-sizing: border-box;
      margin-bottom: 19px;
      textarea {
        width: 98%;
        height: 96%;
        outline: none;
        background: #eeeeee;
        border: 1px solid #cccccc;
        border-radius: 7px;
        line-height: 30px;
        font-size: 14px;
        color: #333;
        padding: 6px 10px;
        box-sizing: border-box;
      }
    }
    .uploadBtn {
      margin-bottom: 44px;
      //    width: 100%;
      .uploadBtn_solt {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 68px;
        border-radius: 7px;
        border: 1px dashed #cccccc;
        img {
          width: 34px;
          height: 29px;
        }
        span {
          color: #666666;
          font-size: 13px;
          margin-left: 10px;
        }
      }
    }
    .submitBtn {
      margin-bottom: 30px;
    }
  }
}
</style>
